// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-restaurant-index-js": () => import("./../src/templates/Restaurant/index.js" /* webpackChunkName: "component---src-templates-restaurant-index-js" */),
  "component---src-components-home-index-js": () => import("./../src/components/Home/index.js" /* webpackChunkName: "component---src-components-home-index-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-faq-js": () => import("./../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */)
}

